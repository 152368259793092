import { useEffect, useRef, useState } from "react";
import { Input } from "antd";
import {
  CloseCircleOutlined,
  EditOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import "../styles/typography/icons.scss";

interface EditableTextProps {
  text: string | undefined;
  style?: React.CSSProperties;
  saveCallback: (text: string) => void;
}

export const EditableText = ({
  text,
  style,
  saveCallback,
}: EditableTextProps) => {
  const { t } = useTranslation("translations");

  const [isEditing, setIsEditing] = useState(false);
  const [titleText, setTitle] = useState("");
  const [tempText, setTempText] = useState(titleText);

  const ref = useRef<Input>(null);

  useEffect(() => {
    setTitle(text ?? "");
    setTempText(text ?? "");
  }, [text]);

  useEffect(() => {
    document.addEventListener("click", handleClickOutsideText);
    return () => {
      document.removeEventListener("click", handleClickOutsideText);
    };
  }, [isEditing, tempText]);

  const handleSaveClick = () => {
    setTitle(tempText);
    setIsEditing(false);
    saveCallback(tempText);
  };

  const handleCancelClick = () => {
    setTempText(titleText);
    setIsEditing(false);
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleClickOutsideText = (event: MouseEvent) => {
    if (
      ref.current &&
      ref.current.input &&
      !ref.current.input.contains(event.target as Node) &&
      isEditing
    ) {
      handleSaveClick();
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempText(e.target.value);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveClick();
    }
  };

  return (
    <span style={{ display: "inline-flex", alignItems: "center" }}>
      {isEditing ? (
        <Input
          aria-label="editable-text-input"
          value={tempText}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          style={style}
          ref={ref}
        />
      ) : (
        <span onClick={handleEditClick} style={{ cursor: "pointer" }}>
          {titleText}
        </span>
      )}
      {!isEditing ? (
        <span>
          <EditOutlined
            onClick={() => handleEditClick()}
            className="icon-drawer"
          />
        </span>
      ) : (
        <span>
          <SaveOutlined
            onClick={() => handleSaveClick()}
            className="icon-drawer-save"
            title={t("translations:save")}
          />
          <CloseCircleOutlined
            onClick={() => handleCancelClick()}
            className="icon-drawer-cancel"
            title={t("translations:cancel")}
          />
        </span>
      )}
    </span>
  );
};
