import { SettingOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  PutBeaconConfigurationRequest,
  SensorTypes,
  useConfigureBeacons,
} from "../../api";
import { Badge } from "../Badge";
import { BulkCongfigureForm } from "./BulkConfigureForm";

interface BulkConfigureModalProps {
  selectedBeaconIds: string[];
}

export function BulkConfigureModal({
  selectedBeaconIds,
}: BulkConfigureModalProps) {
  const { t } = useTranslation();

  const [form] = Form.useForm();
  const [isOpen, setIsOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);
  const [sensorType, setSensorType] = useState(SensorTypes.NTRIP);

  const configureBeacons = useConfigureBeacons();

  const saveConfiguration = async () => {
    await form.validateFields();

    try {
      const values = await form.validateFields();

      setIsSaving(true);

      const configurationPayloadNTRIP: PutBeaconConfigurationRequest = {
        activityIntervalPeriod: values.activityInterval,
        motionDetectionThreshold: 1,
        correctionServiceType: sensorType,
        correctionServiceConfiguration: {
          endpoint: values.correctionServer,
          port: values.correctonServerPort,
        },
        ntripConfiguration: {
          mountpoint: values.NTRIPServerMount,
          username: values.NTRIPClientUsername,
          password: values.NTRUPClientPassword,
          ntripRequestInterval: values.NTRIPSendInterval,
        },
        pointPerfectConfiguration: {
          correctionTopic: "",
          distributionTopic: "",
          clientId: "",
        },
      };

      const configurationPayloadPointPerfect: PutBeaconConfigurationRequest = {
        activityIntervalPeriod: values.activityInterval,
        motionDetectionThreshold: 1,
        correctionServiceType: sensorType,
        correctionServiceConfiguration: {
          endpoint: values.correctionServer,
          port: values.correctonServerPort,
        },
        ntripConfiguration: {
          mountpoint: "",
          username: "",
          password: "",
          ntripRequestInterval: 0,
        },
        pointPerfectConfiguration: {
          correctionTopic: values.PPCorrectionTopic,
          distributionTopic: values.PPDistributionTopic,
          clientId: "", //TODO what is the clientId here?
        },
      };

      await configureBeacons({
        beaconIds: selectedBeaconIds,
        configuration: configurationPayloadPointPerfect,
      });

      setIsSaving(false);
      resetModal();
    } catch (error) {
      console.error("ERROR", error);
      setIsError(true);
      setIsSaving(false);
    }
  };

  const updateSensorType = (sensorType: SensorTypes) => {
    setSensorType(sensorType);
  };

  const resetModal = () => {
    form.resetFields();
    setSensorType(SensorTypes.NTRIP);
    setIsError(false);
    setIsOpen(false);
  };

  return (
    <>
      <Badge count={selectedBeaconIds.length}>
        <Button
          color="default"
          disabled={selectedBeaconIds.length === 0}
          onClick={() => {
            setIsOpen(true);
          }}
          title={t("construction-site:unassign-beacons", {
            count: selectedBeaconIds.length,
          })}
        >
          <SettingOutlined />
          {t("beacon:configure-selected", {
            count: selectedBeaconIds.length,
          })}
        </Button>
      </Badge>
      <Modal
        width="50%"
        style={{ top: Math.max(16, window.innerHeight * 0.15 - 77) }}
        bodyStyle={{ overflowY: "auto" }}
        title={t("beacon:bulk-configuration")}
        visible={isOpen}
        cancelText={t("translations:cancel")}
        onCancel={() => resetModal()}
        okText={t("translations:save")}
        onOk={saveConfiguration}
        confirmLoading={isSaving}
        destroyOnClose
      >
        <div style={{ marginBottom: ".5em" }}>
          {t("beacon:configuration-title-plural", {
            ids: selectedBeaconIds,
          })}
        </div>
        <BulkCongfigureForm
          form={form}
          sensorType={sensorType}
          updateSensorType={updateSensorType}
        />
        {isError ? (
          <div style={{ textAlign: "center", color: "red" }}>
            {t("beacon:bulk-configuration-failed")}
          </div>
        ) : (
          <div style={{ padding: "11px" }}> </div>
        )}
      </Modal>
    </>
  );
}
