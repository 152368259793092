import { InputNumber, Input, Button } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  GetBeaconConfigurationResponse,
  GetBeaconConfigurationStateResponse,
  PutBeaconConfigurationRequest,
  useBeaconConfiguration,
  CorrectionServiceType,
} from "../../api";
import { AuthContext } from "../../contexts/authContext";
import {
  get,
  useOnChangeListenerDynamic,
} from "../../util/useOnChangeListener";

interface BeaconConfigurationFormProps {
  beacon: Beacon;
  reloadToken: number;
  mode: "readonly" | "edit";
  editedConfiguration?: PutBeaconConfigurationRequest;
  onConfigurationEdited(
    edited: PutBeaconConfigurationRequest,
    original: GetBeaconConfigurationResponse
  ): void;
}

function ConfigurationValueStatus<T>(props: { desired?: T; reported?: T }) {
  const { desired, reported } = props;

  if (!desired && !reported) {
    return <QuestionCircleOutlined style={{ color: "orange", marginTop: 4 }} />;
  }

  if (desired !== reported) {
    return <CloseCircleOutlined style={{ color: "red", marginTop: 4 }} />;
  }
  return <CheckCircleOutlined style={{ color: "green", marginTop: 4 }} />;
}

type Config = PutBeaconConfigurationRequest | GetBeaconConfigurationResponse;

interface BeaconConfigurationFieldProps {
  mode: "readonly" | "edit";
  translationKey: string;
  current: GetBeaconConfigurationStateResponse | undefined;
  edited: PutBeaconConfigurationRequest;
  onEdited(e: PutBeaconConfigurationRequest): void;
}

interface BeaconConfigurationStringFieldProps
  extends BeaconConfigurationFieldProps {
  number?: false | undefined;
  property: keyof Config;
}

interface BeaconConfigurationStringFieldProps2<P1 extends keyof Config>
  extends BeaconConfigurationFieldProps {
  number?: false | undefined;
  property: [P1, keyof NonNullable<NonNullable<Config>[P1]>];
}

interface BeaconConfigurationNumberFieldProps
  extends BeaconConfigurationFieldProps {
  number: true;
  property: keyof Config;
}

interface BeaconConfigurationNumberFieldProps2<P1 extends keyof Config>
  extends BeaconConfigurationFieldProps {
  number: true;
  property: [P1, keyof NonNullable<NonNullable<Config>[P1]>];
}

function getProperty<P1 extends keyof Config>(
  config: Config | undefined,
  property: keyof Config | [P1, keyof NonNullable<NonNullable<Config>[P1]>]
) {
  if (!config) {
    return undefined;
  }

  return Array.isArray(property)
    ? get(config, property[0], property[1])
    : get(config, property);
}

function BeaconConfigurationField<P1 extends keyof Config>(
  props:
    | BeaconConfigurationStringFieldProps
    | BeaconConfigurationStringFieldProps2<P1>
    | BeaconConfigurationNumberFieldProps
    | BeaconConfigurationNumberFieldProps2<P1>
) {
  const { t } = useTranslation();
  const { mode, translationKey, number, current, edited, onEdited, property } =
    props;

  const desired = getProperty<P1>(current?.desired, property);
  const reported = getProperty<P1>(current?.reported, property);
  const value = getProperty<P1>(edited, property);
  const onChanged = useOnChangeListenerDynamic(edited, onEdited, property);

  return (
    <>
      <span className="field">
        {t("beacon:configuration-" + translationKey)}
      </span>
      <ConfigurationValueStatus desired={desired} reported={reported} />
      {number ? (
        <>
          {mode === "edit" && !!current ? (
            <InputNumber
              className="align-right"
              style={{ width: "100%" }}
              onChange={onChanged as any}
              value={value as number}
              step={1}
            />
          ) : (
            <span className="field read-only">
              {(desired as number)?.toLocaleString() || "-"}
            </span>
          )}
          <span className="field read-only">
            {(reported as number)?.toLocaleString() || "-"}
          </span>
        </>
      ) : (
        <>
          {mode === "edit" && !!current ? (
            <Input
              className="align-right"
              style={{ width: "100%" }}
              onChange={(e) => onChanged(e.target.value)}
              value={value as any as string}
            />
          ) : (
            <span className="field read-only">{desired || "-"}</span>
          )}
          <span className="field read-only">{reported || "-"}</span>
        </>
      )}
    </>
  );
}

function intitializeConfig(
  e: PutBeaconConfigurationRequest | undefined,
  desired: GetBeaconConfigurationResponse | undefined
): PutBeaconConfigurationRequest {
  if (e) {
    return e;
  }

  return {
    activityIntervalPeriod: desired?.activityIntervalPeriod || 24 * 60 * 60,
    motionDetectionThreshold: desired?.motionDetectionThreshold || 1200,
    correctionServiceType: desired ? desired.correctionServiceType : "NTRIP",
    correctionServiceConfiguration: desired?.correctionServiceConfiguration || {
      endpoint: "",
      port: 0,
    },
    ntripConfiguration: desired?.ntripConfiguration || {
      mountpoint: "",
      username: "",
      password: "",
      ntripRequestInterval: 30,
    },
    pointPerfectConfiguration: desired?.pointPerfectConfiguration || {
      correctionTopic: "/pp/ip/eu",
      distributionTopic: "/pp/key/ip",
      clientId: "",
    },
  };
}

export function BeaconConfigurationForm(props: BeaconConfigurationFormProps) {
  const { t } = useTranslation();
  const { beacon } = props;

  const configuration = useBeaconConfiguration(beacon.id, props.reloadToken);

  const authContext = useContext(AuthContext);
  const currentUserIsSolutionOperator =
    authContext.currentUser?.accessToken?.hasRole("solution-operator");
  const showCorrectionServiceConfiguration = currentUserIsSolutionOperator;

  const desired = configuration.value?.desired;

  const editedConfiguration: PutBeaconConfigurationRequest = intitializeConfig(
    props.editedConfiguration,
    desired
  );

  const onConfigurationEdited = useCallback(
    (c: PutBeaconConfigurationRequest) => {
      props.onConfigurationEdited(c, configuration.value!.desired!);
    },
    [props.onConfigurationEdited, configuration.value]
  );

  const onNtrip = useCallback(() => {
    if (editedConfiguration.correctionServiceType === "NTRIP") {
      return;
    }
    const newConfig: PutBeaconConfigurationRequest = { ...editedConfiguration };
    newConfig.correctionServiceType = "NTRIP";
    onConfigurationEdited(newConfig);
  }, [editedConfiguration, onConfigurationEdited, desired]);

  const onPointPerfect = useCallback(() => {
    if (editedConfiguration.correctionServiceType === "PointPerfect") {
      return;
    }
    const newConfig: PutBeaconConfigurationRequest = { ...editedConfiguration };
    newConfig.correctionServiceType = "PointPerfect";
    onConfigurationEdited(newConfig);
  }, [editedConfiguration, onConfigurationEdited, desired]);

  const usesNtrip = editedConfiguration.correctionServiceType === "NTRIP";

  return (
    <div className="vertical-form four-columns hide-numeric-up-downs">
      <span></span>
      <span className="field">{t("beacon:configuration-status")}</span>
      <span className="field">{t("beacon:configuration-desired")}</span>
      <span className="field">{t("beacon:configuration-reported")}</span>

      <BeaconConfigurationField
        translationKey="activity-interval"
        property="activityIntervalPeriod"
        number
        mode={props.mode}
        current={configuration.value}
        edited={editedConfiguration}
        onEdited={onConfigurationEdited}
      />
      <BeaconConfigurationField
        translationKey="motion-detection-threshold"
        property="motionDetectionThreshold"
        number
        mode={props.mode}
        current={configuration.value}
        edited={editedConfiguration}
        onEdited={onConfigurationEdited}
      />

      {showCorrectionServiceConfiguration && (
        <>
          <BeaconConfigurationField
            translationKey="correction-service-endpoint"
            property={["correctionServiceConfiguration", "endpoint"]}
            mode={props.mode}
            current={configuration.value}
            edited={editedConfiguration}
            onEdited={onConfigurationEdited}
          />
          <BeaconConfigurationField
            translationKey="correction-service-port"
            property={["correctionServiceConfiguration", "port"]}
            number
            mode={props.mode}
            current={configuration.value}
            edited={editedConfiguration}
            onEdited={onConfigurationEdited}
          />

          {props.mode === "edit" && (
            <div className="full">
              <div
                className="multi-button-container"
                style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}
              >
                <Button
                  size="small"
                  onClick={onNtrip}
                  type={usesNtrip ? "primary" : "default"}
                >
                  NTRIP
                </Button>
                <Button
                  size="small"
                  onClick={onPointPerfect}
                  type={usesNtrip ? "default" : "primary"}
                >
                  PointPerfect
                </Button>
              </div>
            </div>
          )}

          {usesNtrip && (
            <>
              <BeaconConfigurationField
                translationKey="ntrip-mountpoint"
                property={["ntripConfiguration", "mountpoint"]}
                mode={props.mode}
                current={configuration.value}
                edited={editedConfiguration}
                onEdited={onConfigurationEdited}
              />
              <BeaconConfigurationField
                translationKey="ntrip-username"
                property={["ntripConfiguration", "username"]}
                mode={props.mode}
                current={configuration.value}
                edited={editedConfiguration}
                onEdited={onConfigurationEdited}
              />
              <BeaconConfigurationField
                translationKey="ntrip-password"
                property={["ntripConfiguration", "password"]}
                mode={props.mode}
                current={configuration.value}
                edited={editedConfiguration}
                onEdited={onConfigurationEdited}
              />
              <BeaconConfigurationField
                translationKey="ntrip-interval"
                property={["ntripConfiguration", "ntripRequestInterval"]}
                number
                mode={props.mode}
                current={configuration.value}
                edited={editedConfiguration}
                onEdited={onConfigurationEdited}
              />
            </>
          )}

          {!usesNtrip && (
            <>
              <BeaconConfigurationField
                translationKey="pp-ct"
                property={["pointPerfectConfiguration", "correctionTopic"]}
                mode={props.mode}
                current={configuration.value}
                edited={editedConfiguration}
                onEdited={onConfigurationEdited}
              />
              <BeaconConfigurationField
                translationKey="pp-dt"
                property={["pointPerfectConfiguration", "distributionTopic"]}
                mode={props.mode}
                current={configuration.value}
                edited={editedConfiguration}
                onEdited={onConfigurationEdited}
              />
            </>
          )}
        </>
      )}
    </div>
  );
}
