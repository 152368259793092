import { Alert } from "antd";
import {
  Icon,
  LatLng,
  latLngBounds,
  LatLngBounds,
  Map as LeafletMap,
} from "leaflet";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Circle,
  MapContainer,
  Marker,
  Polygon,
  Polyline,
  TileLayer,
  Tooltip,
  useMap,
} from "react-leaflet";
import { LinearRing, MarkerColor } from "../api";
import { useDimension } from "../util/useDimension";

const createMarkerIcon = (color: string) =>
  new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-${color}.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

const icons: Record<string, Icon> = {
  blue: createMarkerIcon("blue"),
  green: createMarkerIcon("green"),
  grey: createMarkerIcon("grey"),
  red: createMarkerIcon("red"),
  orange: createMarkerIcon("orange"),
};

export interface Position {
  lat: number;
  lon: number;
}

export interface CirclePosition extends Position {
  radiusInMeters: number;
  title?: string;
  color?: React.CSSProperties["color"];
  onClick?: () => void;
}

export interface MarkerPosition extends Position {
  title: string;
  color?: MarkerColor;
  hasError?: boolean;
  errorText?: React.ReactNode;
  errorSeverity?: "error" | "warning";
  onClick?: () => void;
}

export interface MapPolygon {
  title?: string;
  ring: LinearRing;
  color?: React.CSSProperties["color"];
  id?: string;
  onClick?: () => void;
}

export interface MapPolyline {
  line: Position[];
  title?: string;
  color?: React.CSSProperties["color"];
  width?: number;
  onClick?: () => void;
}

interface MapProps {
  markers: MarkerPosition[];
  circle?: CirclePosition;
  circles?: CirclePosition[];
  allowNoMarkers?: boolean;
  polyLines?: MapPolyline[];
  polygons?: MapPolygon[];
  controls?: React.ReactNode;
  onMapReady?: (map: LeafletMap) => void;
  defaultPosition?: MapPosition;
  onUserDraggedOrZoomed?: (zoom: number, bounds: LatLngBounds) => void;
  onMarkerClicked?: (markerTitle: string) => void;
}

export interface MapPosition {
  bounds: LatLngBounds | undefined;
  zoom: number | undefined;
  center: { lat: number; lng: number } | undefined;
}

declare module "leaflet" {
  interface Map {
    resetUserSelection(): void;
  }
}

function DefaultMapPositionSetter(props: MapPosition) {
  const map = useMap();
  const { bounds, zoom, center } = props;
  const [lastBounds, setLastBounds] = useState<LatLngBounds | undefined>();
  const [lastZoom, setLastZoom] = useState<number | undefined>();
  const [lastCenter, setLastCenter] = useState<
    { lat: number; lng: number } | undefined
  >();

  useEffect(() => {
    if (!map) return;

    if (bounds && (!lastBounds || !lastBounds.equals(bounds))) {
      map.fitBounds(bounds);
      setLastBounds(bounds);
      return;
    }

    if (
      center &&
      zoom &&
      (lastCenter?.lat !== center.lat ||
        lastCenter.lng !== center.lng ||
        lastZoom !== zoom)
    ) {
      map.setView(center, zoom);
      setLastCenter(center);
      setLastZoom(zoom);
    }
  }, [map, bounds, zoom, center, lastBounds, lastZoom, lastCenter]);

  return null;
}

export function getDefaultMapPosition(
  markers?: MarkerPosition[],
  polygons?: MapPolygon[]
): MapPosition {
  let bounds: LatLngBounds | undefined = undefined;
  let zoom: number | undefined = 19;
  let center: { lat: number; lng: number } | undefined = undefined;

  if (!markers?.length && !polygons?.length) {
    // Zoom to all of germany
    bounds = undefined;
    zoom = 6;
    center = { lat: 51.37855903090376, lng: 10.052490234375002 };
  } else if (markers?.length === 1 && !polygons?.length) {
    bounds = undefined;
    zoom = 19;
    center = { lat: markers[0].lat, lng: markers[0].lon };
  } else {
    const markerPositions = (markers || []).map((m) => ({
      lat: m.lat,
      lng: m.lon,
    }));
    const polygonPositions = polygons
      ? polygons.flatMap((polygon) =>
          polygon.ring.map((point) => ({
            lat: point.latitude,
            lng: point.longitude,
          }))
        )
      : [];
    bounds = latLngBounds([...markerPositions, ...polygonPositions]);
    zoom = undefined;
    center = undefined;
  }

  return { bounds, zoom, center };
}

export function Map(props: MapProps) {
  const { t } = useTranslation();
  const noop = useCallback(() => {}, []);

  const [userCenter, setUserCenter] = useState<LatLng>();
  const [userZoom, setUserZoom] = useState<number>();
  const { onMapReady, onUserDraggedOrZoomed } = props;

  const onDraggedOrZoomed = useCallback(
    (map: LeafletMap) => {
      setUserCenter(map.getCenter());
      setUserZoom(map.getZoom());
      onUserDraggedOrZoomed?.(map.getZoom(), map.getBounds());
    },
    [onUserDraggedOrZoomed]
  );

  const containerRef = useRef<HTMLDivElement>(null!);
  const dimension = useDimension(containerRef);
  const setDimensionHandler = dimension.setHandler;

  const whenCreated = useCallback(
    (map: LeafletMap) => {
      map.on("dragend", () => onDraggedOrZoomed(map));
      map.on("zoomend", () => onDraggedOrZoomed(map));

      map.resetUserSelection = () => {
        setUserZoom(undefined);
        setUserCenter(undefined);
      };

      onMapReady?.(map);

      setDimensionHandler(() => {
        try {
          map.invalidateSize();
        } catch {}
      });
    },
    [onMapReady, onDraggedOrZoomed, setDimensionHandler]
  );

  if (props.markers.length === 0 && !props.allowNoMarkers) {
    return (
      <Alert
        message={t("no-data")}
        type="info"
        showIcon
        style={{ alignSelf: "flex-start" }}
      />
    );
  }

  const defaultPosition =
    props.defaultPosition ||
    getDefaultMapPosition(props.markers, props.polygons);

  const effectiveZoom = userZoom || defaultPosition.zoom;
  const effectiveCenter = userCenter || defaultPosition.center;
  const hasZoomAndCenter = !!effectiveZoom && !!effectiveCenter;

  return (
    <div
      data-testid="beacon-detail-map"
      style={{ height: "100%", width: "100%" }}
      ref={containerRef}
    >
      <MapContainer
        style={{ height: "100%", width: "100%" }}
        center={hasZoomAndCenter ? effectiveCenter : undefined}
        zoom={hasZoomAndCenter ? effectiveZoom : undefined}
        bounds={hasZoomAndCenter ? undefined : defaultPosition.bounds}
        maxZoom={25}
        whenCreated={whenCreated}
      >
        {props.controls}
        <DefaultMapPositionSetter
          bounds={defaultPosition.bounds}
          center={defaultPosition.center}
          zoom={defaultPosition.zoom}
        />
        <TileLayer
          maxZoom={25}
          maxNativeZoom={19}
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {props.markers.map((m, i) => (
          <Marker
            title={m.title}
            key={"marker_" + i}
            icon={icons[m.color || "blue"]}
            position={[m.lat, m.lon]}
            eventHandlers={{
              click: () => {
                props.onMarkerClicked?.(m.title);
                m.onClick?.();
              },
            }}
          >
            <Tooltip offset={[10, -28]}>{m.title}</Tooltip>
          </Marker>
        ))}
        {props.polyLines?.map((polyline, i) => (
          <Polyline
            key={"polyline_" + i}
            color={polyline.color || "#1890ff"}
            weight={polyline.width || 3}
            positions={polyline.line.map((point) => [point.lat, point.lon])}
            eventHandlers={{ click: polyline.onClick || noop }}
          >
            {polyline.title && <Tooltip>{polyline.title}</Tooltip>}
          </Polyline>
        ))}
        {props.polygons?.map((polygon, i) => (
          <Polygon
            key={"polygon_" + i}
            color={polygon.color || "#1890ff"}
            positions={polygon.ring.map((p) => [p.latitude, p.longitude])}
            eventHandlers={{
              click: () => {
                props.onMarkerClicked?.(polygon.id || "");
                polygon.onClick?.();
              },
            }}
          >
            {polygon.title && <Tooltip>{polygon.title}</Tooltip>}
          </Polygon>
        ))}
        {props.circle && (
          <Circle
            center={[props.circle.lat, props.circle.lon]}
            radius={props.circle.radiusInMeters}
          />
        )}
        {props.circles?.map((c, i) => (
          <Circle
            key={"circle_" + i}
            color={c.color || "#1890ff"}
            center={[c.lat, c.lon]}
            radius={c.radiusInMeters}
            eventHandlers={{ click: c.onClick || noop }}
          >
            {c.title && <Tooltip>{c.title}</Tooltip>}
          </Circle>
        ))}
      </MapContainer>
    </div>
  );
}
