import { Button, Form, FormInstance, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { SensorTypes } from "../../api";

type buttonType = "primary" | "default";

interface BulkCongfigureFormProps {
  form: FormInstance;
  sensorType: SensorTypes;
  updateSensorType: (sensorType: SensorTypes) => void;
}
type FieldType = {
  activityInterval: number;
  correctionServer: string;
  correctonServerPort: number;
  PPCorrectionTopic: string;
  PPDistributionTopic: string;
  NTRIPServerMount: string;
  NTRIPClientUsername: string;
  NTRUPClientPassword: string;
  NTRIPSendInterval: number;
};

export const BulkCongfigureForm = ({
  form,
  sensorType,
  updateSensorType,
}: BulkCongfigureFormProps) => {
  const [toggleButtonColor, setToggleButtonColor] = useState<buttonType[]>([
    "primary",
    "default",
  ]);

  const toggleType = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.id === SensorTypes.NTRIP) {
      setToggleButtonColor(["primary", "default"]);
      updateSensorType(SensorTypes.NTRIP);
    } else {
      setToggleButtonColor(["default", "primary"]);
      updateSensorType(SensorTypes.PointPerfect);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "16px",
        }}
      >
        <Button
          type={toggleButtonColor[0]}
          id={SensorTypes.NTRIP}
          onClick={toggleType}
          style={{ flex: 1 }}
        >
          NTRIP
        </Button>
        <Button
          type={toggleButtonColor[1]}
          id={SensorTypes.PointPerfect}
          onClick={toggleType}
          style={{ flex: 1 }}
        >
          PointPerfect
        </Button>
      </div>

      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        autoComplete="off"
      >
        <BulkConfigureFormFields sensorType={sensorType} />
      </Form>
    </>
  );
};

interface BulkConfigureFormFieldsProps {
  sensorType: SensorTypes;
}

const BulkConfigureFormFields = ({ sensorType }: BulkConfigureFormFieldsProps) => {
    const { t } = useTranslation();
  return (
    <>
      <Form.Item<FieldType>
        label={t("beacon:configuration-activity-interval")}
        name="activityInterval"
        rules={[
          { required: true },
          {
            pattern: /^[0-9]+$/,
            message: "The input must be a number!",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label={t("beacon:configuration-correction-service-endpoint")}
        name="correctionServer"
        rules={[{ required: true }]}
      >
        <Input />
      </Form.Item>
      <Form.Item<FieldType>
        label={t("beacon:configuration-correction-service-port")}
        name="correctonServerPort"
        rules={[
          { required: true },
          {
            pattern: /^[0-9]+$/,
            message: "The input must be a number!",
          },
        ]}
      >
        <Input />
      </Form.Item>

      {/* NTRIP */}
      {sensorType === SensorTypes.NTRIP && (
        <>
          <Form.Item<FieldType>
            label={t("beacon:configuration-ntrip-mountpoint")}
            name="NTRIPServerMount"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label={t("beacon:configuration-ntrip-username")}
            name="NTRIPClientUsername"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label={t("beacon:configuration-ntrip-password")}
            name="NTRUPClientPassword"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label={t("beacon:configuration-ntrip-interval")}
            name="NTRIPSendInterval"
            rules={[
              { required: true },
              {
                pattern: /^[0-9]+$/,
                message: "The input must be a number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}

      {/* PointPerfect */}
      {sensorType === SensorTypes.PointPerfect && (
        <>
          <Form.Item<FieldType>
            label={t("beacon:configuration-pp-ct")}
            name="PPCorrectionTopic"
            rules={[
              { required: true },
              {
                pattern: /^[0-9]+$/,
                message: "The input must be a number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item<FieldType>
            label={t("beacon:configuration-pp-dt")}
            name="PPDistributionTopic"
            rules={[
              { required: true },
              {
                pattern: /^[0-9]+$/,
                message: "The input must be a number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </>
      )}
    </>
  );
};
