import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { AmplifyLoadingSpinner } from "@aws-amplify/ui-react";
import { Breadcrumb, Button, Card, Dropdown, Menu, Tabs } from "antd";
import { useCallback, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import {
  OrganizationMutableProperties,
  useDeleteOrganization,
  useOrganization,
  useUpdateOrganization,
} from "../../api";
import { ActionsIcon } from "../../components/ActionsIcon";
import { OrganizationPropertyModal } from "../../components/OrganizationPropertyModal";
import { OrganizationUsersTable } from "../../components/UserTable";
import { AuthContext } from "../../contexts/authContext";
import { AssignBeaconsModalOrganization } from "../../components/beacon-configuration/AssignBeaconsModal";
import { AssignUsersModal } from "./AssignUsersModal";
import { OrganizationBeaconTable } from "./OrganizationBeaconTable";
import { OrganizationUserTableFooter } from "./OrganizationUserTableFooter";

export function Organization() {
  const { organizationId } = useParams<{ organizationId: string }>();
  const organization = useOrganization(organizationId);
  const { t } = useTranslation();
  const history = useHistory();
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);

  const [activeTab, setActiveTab] = useState<"users" | "beacons">("users");

  const authContext = useContext(AuthContext);
  const currentUserIsUserAdministrator =
    authContext.currentUser?.accessToken?.hasRole("user-administrator");
  const currentUserIsSolutionOperator =
    authContext.currentUser?.accessToken?.hasRole("solution-operator");

  const deleteOrganization = useDeleteOrganization(organizationId);
  const [isDeleting, setIsDeleting] = useState(false);
  const onDelete = async () => {
    setIsDeleting(true);
    try {
      await deleteOrganization();
      history.push("/organizations");
    } finally {
      setIsDeleting(false);
    }
  };

  const update = useUpdateOrganization(organizationId);
  const onSaveEditing = useCallback(
    async (org: OrganizationMutableProperties) => {
      await update(org);
      organization.refresh();
    },
    [update, organization.refresh]
  );

  if (organization.loading || !organization.value || isDeleting) {
    return <AmplifyLoadingSpinner />;
  }

  const usersTable = () => (
    <OrganizationUsersTable
      {...{ organizationId, selectedUserIds, setSelectedUserIds }}
      footer={
        <OrganizationUserTableFooter
          {...{ organizationId, selectedUserIds }}
          onClearSelectedUserIds={() => setSelectedUserIds([])}
        />
      }
    />
  );

  const beaconsTable = () => {
    return <OrganizationBeaconTable organizationId={organizationId} />;
  };

  return (
    <div className="screen-with-breadcrumb">
      <Breadcrumb>
        <Breadcrumb.Item>
          <a
            href="/organizations"
            onClick={(e) => {
              e.preventDefault();
              history.push("/organizations");
            }}
          >
            {t("organizations")}
          </a>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{organization.value.name}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        style={{
          display: "grid",
          gridTemplateRows: "auto 1fr",
          gridRowGap: "1em",
        }}
      >
        <Card
          title={
            <div
              style={{ display: "grid", gridTemplateColumns: "auto 1fr auto" }}
            >
              <span>{t("organization")}</span>
              <span></span>

              <Dropdown
                trigger={["click"]}
                overlay={
                  <Menu className="actions-menu">
                    {currentUserIsUserAdministrator && (
                      <Menu.Item key="delete">
                        <Button danger type="primary" onClick={onDelete}>
                          <DeleteOutlined /> {t("delete")}
                        </Button>
                      </Menu.Item>
                    )}
                    {currentUserIsUserAdministrator && (
                      <Menu.Item key="edit">
                        <OrganizationPropertyModal
                          onSave={onSaveEditing}
                          modalButtonIcon={<EditOutlined />}
                          title={t("edit")}
                          organization={organization.value}
                        />
                      </Menu.Item>
                    )}
                    {currentUserIsUserAdministrator && (
                      <Menu.Item>
                        <AssignUsersModal organizationId={organizationId} />
                      </Menu.Item>
                    )}
                    {currentUserIsSolutionOperator && (
                      <Menu.Item>
                        <AssignBeaconsModalOrganization
                          organizationId={organizationId}
                        />
                      </Menu.Item>
                    )}
                  </Menu>
                }
              >
                <Button>
                  <ActionsIcon />
                </Button>
              </Dropdown>
            </div>
          }
        >
          <div className="property-list one">
            <span className="property-label">{t("organization:name")}</span>
            <span className="property-value">{organization.value.name}</span>
          </div>
        </Card>
        {currentUserIsSolutionOperator && currentUserIsUserAdministrator ? (
          <Tabs activeKey={activeTab} onChange={(k) => setActiveTab(k as any)}>
            <Tabs.TabPane tab={t("users")} key="users">
              {usersTable()}
            </Tabs.TabPane>
            <Tabs.TabPane tab={t("beacons")} key="beacons">
              {beaconsTable()}
            </Tabs.TabPane>
          </Tabs>
        ) : currentUserIsUserAdministrator ? (
          usersTable()
        ) : (
          currentUserIsSolutionOperator && beaconsTable()
        )}
      </div>
    </div>
  );
}
