import { useState } from "react";
import {
  BeaconTableColumns,
  BeaconTableSorting,
  useOrganizationBeacons,
} from "../../api";
import { BeaconTableBase } from "../../components/BeaconTableBase";
import { OrganizationBeaconTableFooter } from "./OrganizationBeaconTableFooter";

interface OrganizationBeaconTableProps {
  organizationId: string;
}

const useSorting = () => {
  const [sorting, setSorting] = useState<BeaconTableSorting>({
    sortColumnId: undefined,
    sortOrderId: undefined,
  });

  const onSortingChange = (sorting: BeaconTableSorting) => {
    setSorting(sorting);
  };

  return { sorting, onSortingChange };
};

const useSelectedBeacons = () => {
  const [selectedBeaconIds, setSelectedBeaconIds] = useState<string[]>([]);

  const onSelect = (selectedRowKeys: string[]) => {
    setSelectedBeaconIds(selectedRowKeys);
  };

  const clearSelectedBeaconIds = () => {
    setSelectedBeaconIds([]);
  };

  return { selectedBeaconIds, onSelect, clearSelectedBeaconIds };
}

/*
  This component is used to display a table of beacons that belong to an organization.
  Component and file naming convention follows {screen}BeaconsTabkle.tsx. 
*/
export function OrganizationBeaconTable({
  organizationId,
}: OrganizationBeaconTableProps) {
  const { sorting, onSortingChange } = useSorting();
  const { selectedBeaconIds, onSelect, clearSelectedBeaconIds } = useSelectedBeacons();
  const orgBeacons = useOrganizationBeacons(organizationId, sorting);

  return (
    <BeaconTableBase
      beaconData={orgBeacons}
      visibleColumns={[
        BeaconTableColumns.Serial,
        BeaconTableColumns.Status,
        BeaconTableColumns.Iccid,
        BeaconTableColumns.ConfigurationButton,
      ]}
      showFooter={true}
      showClearSelected={true}
      footerContent={
        <OrganizationBeaconTableFooter
          {...{ organizationId, selectedBeaconIds }}
          onClearSelectedBeaconIds={clearSelectedBeaconIds}
        />
      }
      rowsSelectable={true}
      onSort={onSortingChange}
      onSelect={onSelect}
    />
  );
}
