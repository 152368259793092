import {
  BorderInnerOutlined,
  BorderOuterOutlined,
  EnvironmentOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
  InfoCircleOutlined,
  MinusSquareOutlined,
  PlusSquareOutlined,
  TeamOutlined,
} from "@ant-design/icons";
import { useState } from "react";
import "./../../../styles/typography/icons.scss";
import { TreeNode } from "./treeUtils";

interface IconTreeProps {
  data: TreeNode | undefined;
  id: string;
  handleIconClick: (node: TreeNode | undefined, action: string) => void;
}

interface IconTreePropsWithState extends IconTreeProps {
  disabled: boolean;
}

export const BeaconIcon = () => <EnvironmentOutlined className="icon" />;
export const ProjectIcon = () => <BorderOuterOutlined className="icon" />;
export const SiteIcon = () => <BorderInnerOutlined className="icon" />;
export const OrganizationIcon = () => <TeamOutlined className="icon" />;

const IconWrapper = ({
  IconComponent,
  ariaLabel,
  className,
  onClick,
}: {
  IconComponent: React.ComponentType<any>;
  ariaLabel: string;
  className?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}) => (
  <IconComponent aria-label={ariaLabel} className={className} onClick={onClick} />
);

export const InfoIcon = ({ data, id, handleIconClick }: IconTreeProps) => (
  <IconWrapper
    IconComponent={InfoCircleOutlined}
    ariaLabel="info"
    className="icon"
    onClick={() => handleIconClick(data, id)}
  />
);

const EyeIconBase = ({
  IconComponent,
  ariaLabel,
  className,
  onClick,
}: {
  IconComponent: React.ComponentType<any>;
  ariaLabel: string;
  className?: string;
  onClick: React.MouseEventHandler<HTMLElement>;
}) => (
  <IconWrapper
    IconComponent={IconComponent}
    ariaLabel={ariaLabel}
    className={className}
    onClick={onClick}
  />
);

export const EyeIcon = ({
  data,
  id,
  disabled,
  handleIconClick,
}: IconTreePropsWithState) => (
  <EyeIconBase
    IconComponent={disabled ? EyeInvisibleOutlined : EyeOutlined}
    ariaLabel={disabled ? "visible-single-disabled" : "visible-single"}
    className={id === "visible-all" ? "icon-bold" : "icon"}
    onClick={() => {
      if (data) handleIconClick(data, disabled ? id : `${id}-disabled`);
    }}
  />
);

export const EyeIconAll = ({
  data,
  id,
  disabled,
  handleIconClick,
}: IconTreePropsWithState) => {
  const [isVisible, setIsVisible] = useState(true);

  if (disabled) return <EyeOutlined />;

  return (
    <EyeIconBase
      IconComponent={isVisible ? EyeOutlined : EyeInvisibleOutlined}
      ariaLabel={isVisible ? "visible-all" : "visible-all-disabled"}
      className={id === "visible-all" ? "icon-bold" : "icon"}
      onClick={() => {
        handleIconClick(data, isVisible ? `${id}-disabled` : id);
        setIsVisible(!isVisible);
      }}
    />
  );
};

export const ExpandIcon = ({
  data,
  id,
  disabled,
  handleIconClick,
}: IconTreePropsWithState) => {
  const [isExpanded, setIsExpanded] = useState(false);

  if (disabled) return <PlusSquareOutlined />;

  return (
    <IconWrapper
      IconComponent={isExpanded ? MinusSquareOutlined : PlusSquareOutlined}
      ariaLabel={isExpanded ? "expand-all-disabled" : "expand-all"}
      onClick={() => {
        handleIconClick(data, isExpanded ? `${id}-disabled` : id);
        setIsExpanded(!isExpanded);
      }}
    />
  );
};
