import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { NavigatableText } from "../../../../util/NavigatableText";

interface SummaryInformationProps {
  dateCreated: Date;
  location: string;
  project: string;
  projectId: string;
  isLoading: boolean;
  handleProjectClicked: (siteId: string) => void;
}

export const SummaryInformation = ({
  dateCreated,
  project,
  projectId,
  location,
  isLoading,
  handleProjectClicked,
}: SummaryInformationProps) => {
  const { t } = useTranslation();

  const handleProjectNameClick = (params: { [key: string]: string }) => {
    if (handleProjectClicked) {
      handleProjectClicked(params.projectId);
    }
  };

  return (
    <div>
      {isLoading ? (
        <Spin size="small" />
      ) : (
        <table className="summary-table">
          <tbody>
            <tr>
              <td className={"align-right"}>
                {t("translations:created-date")}:&nbsp;&nbsp;
              </td>
              <td>{dateCreated.toLocaleString()}</td>
            </tr>
            <tr>
              <td className={"align-right"}>
                {t("translations:construction-project")}:&nbsp;&nbsp;
              </td>
              <td>
                <NavigatableText
                  text={project}
                  params={{ projectId: projectId }}
                  type="callback"
                  callBack={handleProjectNameClick}
                />
              </td>
            </tr>
            <tr>
              {
                //NOT AVAILABLE Jan 2025
                /*
              <td className={"align-right"}>
                {t("translations:location")}:&nbsp;&nbsp;
              </td>
              <td>
                <EditableText
                  text={location}
                  style={{ height: "20px", width: "300px" }}
                  saveCallback={(value) => {
                    console.log(value);
                  }}
                />
              </td> */
              }
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
