import { useState } from "react";
import {
  ConstructionProject,
  useUpdateConstructionProject,
  useUpdateConstructionSite,
} from "../../../api";

export interface UpdateApiResponse {
  updatedValue: { key: string; value: string };
  completed: boolean;
  status: number;
}

export const useUpdateConstructionProjectInformation = (
  constructionProjectData: ConstructionProject | undefined,
  onSave: (refreshTimeStamp: string) => void
) => {
  const [updateResult, setUpdateResult] = useState<UpdateApiResponse>({
    updatedValue: { key: "", value: "" },
    completed: true,
    status: 0,
  });

  const update = useUpdateConstructionProject(
    constructionProjectData?.id || ""
  );

  // API is destructive for existing data, so we need to update the data object before sending it to the API
  const updateProjectName = async (name: string) => {
    if (constructionProjectData) {
      constructionProjectData.name = name;
    } else {
      setUpdateResult({
        updatedValue: { key: "", value: "" },
        completed: true,
        status: 401,
      });
      return;
    }
    try {
      setUpdateResult({
        updatedValue: { key: "projectName", value: name },
        completed: false,
        status: 0,
      });

      await update(constructionProjectData);

      setUpdateResult({
        updatedValue: { key: "projectName", value: name },
        completed: true,
        status: 200,
      });

      onSave(new Date().toISOString());
    } catch {
      setUpdateResult({
        updatedValue: { key: "projectName", value: "" },
        completed: true,
        status: 401,
      });
    }
  };

  const updateProjectCity = async (city: string) => {
    if (!constructionProjectData) {
      setUpdateResult({
        updatedValue: { key: "", value: "" },
        completed: true,
        status: 401,
      });
      return;
    }

    if (constructionProjectData?.address) {
      constructionProjectData.address.city = city;
    } else {
      constructionProjectData.address = {
        zipCode: "",
        city: city,
        street: "",
      };
    }

    try {
      setUpdateResult({
        updatedValue: { key: "projectLocation", value: city },
        completed: false,
        status: 0,
      });

      await update(constructionProjectData);
      
      setUpdateResult({
        updatedValue: { key: "projectLocation", value: city },
        completed: true,
        status: 200,
      });
    } catch {
      setUpdateResult({
        updatedValue: { key: "projectLocation", value: "" },
        completed: true,
        status: 401,
      });
    }
  };

  const resetUpdateInfo = () => {
    setUpdateResult({
      updatedValue: { key: "", value: "" },
      completed: true,
      status: 0,
    });
  };

  return {
    updateResult,
    updateProjectName,
    resetUpdateInfo,
    updateProjectCity,
  };
};

export const useUpdateConstructionSiteInformation = (
  constructionProjectId: string,
  constructionSiteId: string,
  onSave: (refreshTimeStamp: string) => void
) => {
  const [updateResult, setUpdateResult] = useState<UpdateApiResponse>({
    updatedValue: { key: "", value: "" },
    completed: true,
    status: 0,
  });

  const update = useUpdateConstructionSite(
    constructionProjectId,
    constructionSiteId
  );

  const updateSiteName = async (name: string) => {
    try {
      setUpdateResult({
        updatedValue: { key: "siteName", value: name },
        completed: false,
        status: 0,
      });

      await update({
        name: name,
      });

      setUpdateResult({
        updatedValue: { key: "siteName", value: name },
        completed: true,
        status: 200,
      });

      onSave(new Date().toISOString());
    } catch {
      setUpdateResult({
        updatedValue: { key: "siteName", value: "" },
        completed: true,
        status: 401,
      });
    }
  };

  const resetUpdateInfo = () => {
    setUpdateResult({
      updatedValue: { key: "", value: "" },
      completed: true,
      status: 0,
    });
  };

  return {
    updateResult,
    updateSiteName,
    resetUpdateInfo,
  };
};
