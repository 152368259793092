import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ConstructionProject as ConstructionProjectInterface,
  useBeacons,
  useConstructionProjects,
  useGetAllConstructionProjectSites,
} from "../../api";
import { useRefreshAll } from "../../util";
import "./construction-project.scss";
import { ConstructionProjectMap } from "./map/ConstructionProjectMap";
import { useNodeSelectedContext } from "./NodeSelectedContext";
import { ConstructionProjectTree } from "./project-tree/ConstructionProjectTree";
import { ConstructionProjectDrawer } from "./drawers/project/ConstructionProjectDrawer";
import { ConstructionSiteDrawer } from "./drawers/site/ConstructionSiteDrawer";

interface HistoryState {
  selectedId: string;
}

export function ConstructionProjectWrapper() {
  const [refreshTimeStamp, setRefreshTimeStamp] = useState<string>("");
  const { selectedNode, setSelectedNode } = useNodeSelectedContext();
  const history = useHistory();

  useEffect(() => {
    if (refreshTimeStamp) {
      refreshAll();
    }
  }, [refreshTimeStamp]);

  useEffect(() => {
    if (
      history.location.state &&
      typeof history.location.state === "object" &&
      "selectedId" in history.location.state
    ) {
      const state = history.location.state as HistoryState;
      setSelectedNode({ selectedId: state.selectedId });
    }
  }, [history]);

  /*
    Fetching beacons with take of 1000 as there is not an api to support the use case for the 
    updated construction-project page.

    If implemented a different wayt to fetch all the beacons, orgs, sites and projects there 
    would be many api calls to then have to compose that data into the structure we can get with one
    api call from the /beacons endpoint with a take of 1000. 

    This will obviously exclude beacons when the total number of beacons is greater than 1000.
  */

  const beacons = useBeacons(undefined, undefined, undefined, undefined, 1000);
  const projects = useConstructionProjects(undefined, false);
  const projectIds = useMemo(
    () =>
      projects.value?.items.map(
        (project: ConstructionProjectInterface) => project.id
      ) || [],
    [projects.loading]
  );
  const sites = useGetAllConstructionProjectSites(projectIds);

  const refreshAll = useRefreshAll(beacons);

  const dataLoading = beacons.loading || projects.loading || sites.loading;

  return (
    <div className="project-container">
      <div className="column-one">
        <ConstructionProjectTree
          beacons={beacons}
          isLoading={dataLoading}
          defaultIdExpanded={selectedNode.selectedId ?? ""}
        />
      </div>
      <div className="column-two">
        <ConstructionProjectMap
          beacons={beacons.value?.items}
          constructionSites={sites.value}
          constructionProjects={projects.value?.items}
          isLoading={dataLoading}
        />
      </div>
      <ConstructionProjectDrawer
        constructionProjectId={selectedNode.selectedProjectId ?? ""}
        constructionProjectName={selectedNode.selectedProjectName ?? ""}
        drawerVisible={selectedNode.projectDrawerVisible ?? false}
        onSave={(refreshTimeStamp: string) =>
          setRefreshTimeStamp(refreshTimeStamp)
        }
      />
      {selectedNode.siteDrawerVisible ? (
        <ConstructionSiteDrawer
          constructionProjectId={selectedNode.selectedProjectId ?? ""}
          beacons={beacons}
          constructionProjectName={selectedNode.selectedProjectName ?? ""}
          constructionSiteId={selectedNode.selectedSiteId ?? ""}
          drawerVisible={selectedNode.siteDrawerVisible ?? false}
          onSave={(refreshTimeStamp: string) =>
            setRefreshTimeStamp(refreshTimeStamp)
          }
        />
      ) : null}
    </div>
  );
}
