import { useState } from "react";
import { BeaconTableColumns, BeaconTableSorting, useBeacons } from "../../api";
import { BeaconTableBase } from "../../components/BeaconTableBase";
interface BeaconsBeaconTableProps {
  currentUserIsSolutionOperator: boolean;
}

const useSorting = () => {
  const [sorting, setSorting] = useState<BeaconTableSorting>({
    sortColumnId: undefined,
    sortOrderId: undefined,
  });

  const onSortingChange = (sorting: BeaconTableSorting) => {
    setSorting(sorting);
  };

  return { sorting, onSortingChange };
};

const useFiltering = () => {
  const [filter, setFilter] = useState<string>("");

  const onFiltering = (filter: string) => {
    /* 
      Debounce filter in BaconTableBase.tsx causes a race case condition (I believe), dude to this 
      setting a timeout of the same duration as the debounce in the BeaconTableBase.tsx
      Not a good solution, but it works for now.
     */
    setTimeout(() => {
      setFilter(filter);
    }, 300);
  };

  return { filter, onFiltering };
};

/*
  This component is used to display a table of beacons on the /beacons screen.
  Component and file naming convention follows {screen}BeaconsTable.tsx. 
  This component name might be a bit confusing, but it is consistent with the 
  naming convention.

  Open to suggestions for a better name.
*/
export function BeaconsBeaconTable({
  currentUserIsSolutionOperator,
}: BeaconsBeaconTableProps) {
  const { sorting, onSortingChange } = useSorting();
  const { filter, onFiltering } = useFiltering();
  const beacons = useBeacons(undefined, filter, undefined, sorting);

  return (
    <BeaconTableBase
      beaconData={beacons}
      visibleColumns={[
        BeaconTableColumns.Serial,
        BeaconTableColumns.Status,
        BeaconTableColumns.ConstructionProjectName,
        BeaconTableColumns.ConstructionSiteName,
        currentUserIsSolutionOperator
          ? BeaconTableColumns.OrganizationName
          : BeaconTableColumns.OmmitColumn,
        BeaconTableColumns.LastMessageReceivedAt,
        currentUserIsSolutionOperator
          ? BeaconTableColumns.ConfigurationButton
          : BeaconTableColumns.OmmitColumn,
      ]}
      showBeaconFilter={true}
      onSort={onSortingChange}
      onFilter={onFiltering}
    />
  );
}
