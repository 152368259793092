import { AssignBeaconsModalOrganization } from "../../components/beacon-configuration/AssignBeaconsModal";
import { UnassignBeaconsButtonOrganization } from "../../components/beacon-configuration/UnassignBeaconsButton";

interface OrganizationBeaconTableFooterProps {
  organizationId: string;
  selectedBeaconIds: string[];
  onClearSelectedBeaconIds(): void;
}

export function OrganizationBeaconTableFooter({
  organizationId,
  selectedBeaconIds,
  onClearSelectedBeaconIds,
}: OrganizationBeaconTableFooterProps) {
  return (
    <>
      <UnassignBeaconsButtonOrganization
        organizationId={organizationId}
        selectedBeaconIds={selectedBeaconIds}
        onClearSelectedBeaconIds={onClearSelectedBeaconIds}
      />
      <AssignBeaconsModalOrganization organizationId={organizationId} />
    </>
  );
}
