import { Beacon, ConstructionProject, ConstructionSite } from "../../../api";
import { Map2 } from "../../../components/Map2";
import { useNodeSelectedContext } from "../NodeSelectedContext";
import {
  getPolygonsProjects,
  getPolygonsSites,
  getPolylinesSites,
  transformBeaconsToMarkers,
} from "./mapUtils";
import { useMemo } from "react";

interface ConstructionProjectMapProps {
  beacons: Beacon[] | undefined;
  constructionSites: ConstructionSite[] | undefined;
  constructionProjects: ConstructionProject[] | undefined;
  isLoading: boolean;
}

export function ConstructionProjectMap({
  beacons,
  constructionSites,
  constructionProjects,
  isLoading,
}: ConstructionProjectMapProps) {
  const { nodeVisitiblyDisabledList, setSelectedNode } =
    useNodeSelectedContext();

  const markers = transformBeaconsToMarkers(beacons, setSelectedNode);

  const filteredMarkers = useMemo(
    () =>
      markers.filter(
        (marker) => !nodeVisitiblyDisabledList.includes(marker.title)
      ),
    [markers, nodeVisitiblyDisabledList]
  );

  const filteredConstructionSites =
    constructionSites?.filter(
      (site) => site && !nodeVisitiblyDisabledList.includes(site.id)
    ) || [];

  const filteredConstructionProjects =
    constructionProjects?.filter(
      (project) => project && !nodeVisitiblyDisabledList.includes(project.id)
    ) || [];

  const combinedPolygons = [
    ...getPolygonsProjects(
      filteredConstructionProjects,
      "#4C86F7",
      setSelectedNode
    ),
    ...getPolygonsSites(filteredConstructionSites, "orange", setSelectedNode),
  ];
  const polylines = getPolylinesSites(
    beacons,
    filteredConstructionSites,
    "orange",
    3,
    setSelectedNode
  );

  return (
    <Map2
      markers={isLoading ? [] : filteredMarkers}
      polylines={isLoading ? [] : polylines}
      polygons={isLoading ? [] : combinedPolygons}
      isLoading={isLoading}
      onMapClicked={() => setSelectedNode({ selectedId: "" })}
    />
  );
}
