import { useHistory } from "react-router-dom";

interface NavigatableText {
  text: string;
  type: "router" | "callback";
  params?: { [key: string]: string };
  route?: string;
  callBack?: (params: { [key: string]: string }) => void;
  style?: React.CSSProperties;
}
export const NavigatableText = ({
  text,
  type,
  params,
  route,
  callBack,
  style,
}: NavigatableText) => {
  const history = useHistory();
  return (
    <span
      onClick={
        type === "router" && route
          ? () => history.push(route)
          : type === "callback" && callBack
          ? () => params && callBack(params)
          : undefined
      }
      style={{
        ...style,
        cursor: "pointer",
        textDecoration: "underline",
        color: "#438EFB",
      }}
    >
      {text}
    </span>
  );
};
