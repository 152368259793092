import { Table } from "antd";
import { useTranslation } from "react-i18next";
import { ConstructionSite, useConstructionProjectSites } from "../../../../api";
import { NavigatableText } from "../../../../util/NavigatableText";
import { ApiState } from "../../../../api/useApiState";

interface SitesDetailsProps {
  constructionProjectId: string;
  constructionProjectName: string;
  handleSiteClicked: (
    siteId: string,
    projectName: string,
    projectId: string
  ) => void;
}

export const SitesDetails = ({
  constructionProjectId,
  constructionProjectName,
  handleSiteClicked,
}: SitesDetailsProps) => {
  const { t } = useTranslation();

  /*
    Ant Design drawer component renders at initial load, so we need to fetch the data
    only after the drawer has become visible, else the API will throw an error. 

    To achieve this, we use the useConstructionProjectSites hook in this child component 
    and only redner the child component when the parent (ProjectDrawer) is visible.
  */
  const constructionProjectSitesData = useConstructionProjectSites(
    constructionProjectId
  );

  const handleSiteNameClick = (params: { [key: string]: string }) => {
    if (handleSiteClicked) {
      handleSiteClicked(params.id, params.projectName, params.projectId);
    }
  };

  const columns = [
    {
      key: "name",
      title: t("construction-site:name"),
      width: "auto",
      render: (s: ConstructionSite) => (
        <NavigatableText
          text={s.name}
          params={{
            id: s.id,
            projectName: constructionProjectName,
            projectId: constructionProjectId,
          }}
          type="callback"
          callBack={handleSiteNameClick}
        />
      ),
    },
    {
      key: "createdAt",
      title: t("translations:created-date"),
      width: "auto",
      render: (s: ConstructionSite) => s.createdAt.toLocaleString(),
    },
  ];
  return (
    <div>
      <div className="sub-title-container">
        {t("construction-site:sites-in-project")}
      </div>
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={constructionProjectSitesData.value}
        pagination={false}
        loading={constructionProjectSitesData.loading}
      />
    </div>
  );
};
