import {
  Beacon,
  ConstructionProject,
  ConstructionSite,
  BeaconWithPosition,
  MarkerColor,
} from "../../../api";
import {
  PolyLineData,
  PolygonData,
  MarkerData,
} from "../../../components/Map2";
import { NodeContextData } from "../NodeSelectedContext";

export const getPolygonsSites = (
  sites: ConstructionSite[],
  color: string,
  onClickEvent: (node: NodeContextData) => void
): PolygonData[] => {
  return sites
    .filter((coord) => coord.polygon !== null)
    .map((coord) => ({
      color: color,
      ring: coord.polygon!,
      title: coord.name,
      id: coord.id,
      onClick: () => onClickEvent({ selectedId: coord.id }),
    }));
};

export const getPolygonsProjects = (
  projects: ConstructionProject[],
  color: string,
  onClickEvent: (node: NodeContextData) => void
): PolygonData[] => {
  return projects
    .filter((coord) => coord.area !== null)
    .map((coord) => ({
      color: color,
      ring: coord.area!,
      title: coord.name,
      id: coord.id,
      onClick: () => onClickEvent({ selectedId: coord.id }),
    }));
};

export const getPolylinesSites = (
  beacons: Beacon[] | undefined,
  sites: ConstructionSite[] | undefined,
  color: string,
  width: number,
  onClickEvent: (node: NodeContextData) => void
): PolyLineData[] => {
  if (!sites || !beacons) return [];

  return sites
    .map((site) => {
      const beaconsForSite =
        beacons.filter((beacon) => beacon.constructionSiteId === site.id) ?? [];
      const count = beaconsForSite.length;
      const positions = beaconsForSite
        .filter(
          (beacon): beacon is BeaconWithPosition =>
            (beacon as BeaconWithPosition).position !== undefined
        )
        .map((beacon) => beacon.position.value.pos);
      return { siteId: site.id, siteName: site.name, positions, count };
    })
    .filter((siteBeaconCount) => siteBeaconCount.count === 2)
    .map((site) => ({
      line: site.positions,
      title: site.siteName,
      id: site.siteId,
      color: color,
      width: width,
      onClick: () => onClickEvent({ selectedId: site.siteId }),
    }));
};

export const transformBeaconsToMarkers = (
  beacons: Beacon[] | undefined,
  onClickEvent: (node: NodeContextData) => void
) => {
  if (beacons === undefined) return [];

  const filteredBeacons = beacons.filter(
    (beacon: Beacon) => beacon.onOffSwitch?.isOn === true
  );

  const markers: MarkerData[] = (filteredBeacons as BeaconWithPosition[])
    .map((beacon) => {
      if (beacon.position === undefined) return undefined;
      return {
        title: beacon.serial,
        lat: beacon.position.value.pos.lat,
        lon: beacon.position.value.pos.lon,
        color: getMarkerColor(beacon),
        onClick: () => onClickEvent({ selectedId: beacon.id }),
      };
    })
    .filter((marker) => marker !== undefined) as MarkerData[];

  return markers;
};

export const getMarkerColor = (beacon: BeaconWithPosition): MarkerColor => {
  if (beacon.onOffSwitch?.isOn === false) {
    return "grey";
  }

  if (beacon.activeErrors?.length) {
    return "red";
  }

  return beacon.position?.value.md.cprs === 2 ? "green" : "orange";
};
