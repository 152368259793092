import { CloseOutlined } from "@ant-design/icons";
import { Alert, Button, Drawer, Spin } from "antd";
import { useContext, useEffect } from "react";
import {
  useConstructionProject,
} from "../../../../api";
import { useRefreshAll } from "../../../../util";
import { EditableText } from "../../../../util/EditableText";
import "../drawer.scss";
import { useUpdateConstructionProjectInformation } from "../useUpdateInformation";
import { SitesDetails } from "./SitesDetails";
import { SummaryInformation } from "./SummaryInformation";
import { useNodeSelectedContext } from "../../NodeSelectedContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../contexts/authContext";

interface ConstructionProjectDrawerProps {
  constructionProjectId: string;
  constructionProjectName: string;
  drawerVisible: boolean;
  onSave: (refreshTimeStamp: string) => void;
}

export function ConstructionProjectDrawer({
  constructionProjectId,
  drawerVisible,
  onSave,
}: ConstructionProjectDrawerProps) {
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const currentUserIsRSM = authContext.currentUser?.accessToken?.hasRole(
    "road-safety-manager"
  );

  const constructionProjectData = useConstructionProject(constructionProjectId);

  const refreshProjectData = useRefreshAll(constructionProjectData);

  const {
    updateResult,
    updateProjectCity,
    updateProjectName,
    resetUpdateInfo,
  } = useUpdateConstructionProjectInformation(
    constructionProjectData.value,
    onSave
  );

  useEffect(() => {
    if (!drawerVisible) resetUpdateInfo();
  }, [drawerVisible]);

  useEffect(() => {
    if (updateResult.status === 200) refreshProjectData();
  }, [updateResult]);

  const { setSelectedNode } = useNodeSelectedContext();

  const showSitesDrawer = (
    siteId: string,
    projectName: string,
    projectId: string
  ) => {
    setSelectedNode({
      selectedSiteId: siteId,
      selectedProjectId: projectId,
      selectedProjectName: projectName,
      siteDrawerVisible: true,
      projectDrawerVisible: false,
    });
  };

  return (
    <Drawer
      title={
        <div>
          {constructionProjectData.loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {true ? <Spin size="default" /> : undefined}
            </div>
          ) : (
            <div className="title-container">
              {currentUserIsRSM ? (
                <EditableText
                  text={constructionProjectData.value?.name}
                  style={{ width: "450px" }}
                  saveCallback={(value) => {
                    updateProjectName(value);
                  }}
                />
              ) : (
                constructionProjectData.value?.name
              )}
              <Button
                type="text"
                className="close-card-drawer-button"
                icon={<CloseOutlined />}
                onClick={() => setSelectedNode({ projectDrawerVisible: false })}
              />
            </div>
          )}
        </div>
      }
      placement="right"
      closable={false}
      onClose={() => setSelectedNode({ projectDrawerVisible: false })}
      visible={drawerVisible}
      destroyOnClose={true}
      width={600}
    >
      {updateResult.status === 401 &&
        updateResult.updatedValue.key === "projectName" && (
          <Alert
            message={t("construction-project:update-project-name-failed")}
            type="error"
            closable
          />
        )}
      {updateResult.status === 200 &&
        updateResult.updatedValue.key === "projectName" && (
          <Alert
            message={t("construction-project:update-project-name-success")}
            type="success"
            closable
          />
        )}
      <SummaryInformation
        dateCreated={new Date("")}
        location={constructionProjectData.value?.address?.city || ""}
        organizationName={constructionProjectData.value?.organizationName || ""}
        organizationId={constructionProjectData.value?.organizationId || ""}
        isLoading={constructionProjectData.loading}
        updateResult={updateResult}
        onSave={updateProjectCity}
      />
      {drawerVisible && (
        <SitesDetails
          constructionProjectName={constructionProjectData.value?.name || ""}
          constructionProjectId={constructionProjectId}
          handleSiteClicked={(siteId, projectName, projectId) => {
            showSitesDrawer(siteId, projectName, projectId);
          }}
        />
      )}
    </Drawer>
  );
}
