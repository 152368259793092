import { SearchOutlined } from "@ant-design/icons";
import { Badge, Button, Input, Table } from "antd";
import { SorterResult } from "antd/lib/table/interface";
import { debounce } from "lodash";
import { useEffect, useRef } from "react";
import { TFunction, useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  Beacon,
  BeaconSortingColumn,
  BeaconSortingOrder,
  BeaconTableColumns,
  BeaconTableSorting,
  ErrorSeverity,
} from "../api";
import { PagedApiState } from "../api/useApiState";
import { toAntdPagination } from "../util";
import { BeaconConfigurationModal } from "./beacon-configuration/BeaconConfigurationModal";
import { BeaconsErrors } from "./BeaconsErrors";
import { BeaconStatus } from "./BeaconStatus";

interface BeaconTableProps {
  beaconData: PagedApiState<Beacon>;
  visibleColumns: BeaconTableColumns[];
  disableCellClick?: boolean;
  showPagination?: boolean;
  showFooter?: boolean;
  showClearSelected?: boolean;
  rowsSelectable?: boolean;
  showBeaconFilter?: boolean;
  showSorter?: boolean;
  footerContent?: React.ReactNode;
  onSort?: (sorting: BeaconTableSorting) => void;
  onFilter?: (filter: string) => void;
  onSelect?: (selectedRowKeys: string[]) => void;
}

export function BeaconTableBase({
  beaconData,
  visibleColumns,
  disableCellClick = false,
  showPagination = true,
  showFooter = false,
  showClearSelected = false,
  rowsSelectable = false,
  showBeaconFilter = false,
  showSorter = true,
  footerContent,
  onSort,
  onFilter,
  onSelect,
}: BeaconTableProps) {
  const { t } = useTranslation();
  const history = useHistory();
  const isPageRendered = useRef<boolean>(false);
  const filter = useRef<string>("");
  const selectedRowKeys = useRef<string[]>([]);

  useEffect(() => {
    if (!isPageRendered.current) {
      isPageRendered.current = true;
    }
  }, []);

  const onSortingChange = (
    sorter: SorterResult<Beacon> | SorterResult<Beacon>[]
  ) => {
    const sort = Array.isArray(sorter) ? sorter[0] : sorter;
    if (onSort) {
      onSort(mapSortingToApiDefintions(sort));
    }
  };

  /*
    When user filters on any page that is not the first page (page 0), the table needs to go back to page 0
    to display the results. When the user removes the filter, the table needs to go back to the page it was 
    before filtering took place. 

    This is done by keeping track of the current page in a ref and updating it when the user filters.
  */
  const previousPage = useRef<number>(0);
  const isFiltering = useRef<boolean>(false);

  const onFilterChange = debounce((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!onFilter) return;

    if (!isFiltering.current) {
      previousPage.current = beaconData.currentPage;
    }

    if (e.target.value === "") {
      isFiltering.current = false;
      beaconData.goToPage(previousPage.current);
    } else {
      isFiltering.current = true;
      beaconData.goToPage(0);
    }

    filter.current = e.target.value;
    onFilter(e.target.value);
  }, 300);

  const onRowSelectChange = (selectedKeys: React.Key[]) => {
    if (onSelect) onSelect(selectedKeys as string[]);
    selectedRowKeys.current = selectedKeys as string[];
  };

  const clearAllSelectedRows = () => {
    if (onSelect) onSelect([] as string[]);
    selectedRowKeys.current = [];
  };

  const handleCellClick = (urlPathPart: string, id: string) => {
    if (urlPathPart === "/construction-projects/" && id) {
      setTimeout(
        () =>
          history.push(urlPathPart, {
            selectedId: id,
          }),
        0
      );
    } else if (id) {
      setTimeout(() => history.push(urlPathPart + id), 0);
    }
  };

  const visibleColumnsDefintion = getVisibleColumnDefinitions(
    t,
    handleCellClick,
    visibleColumns,
    disableCellClick,
    showSorter
  );

  return (
    <>
      {showBeaconFilter && (
        <div style={{ display: "flex", alignItems: "center" }}>
          {beaconFilter(onFilterChange, filter.current, t)}
          {beaconAlerts(filter.current)}
        </div>
      )}
      <Table
        dataSource={beaconData.value?.items ?? []}
        loading={beaconData.loading}
        columns={visibleColumnsDefintion}
        size="middle"
        pagination={
          showPagination ? toAntdPagination(beaconData, false) : false
        }
        onChange={showSorter ? (_, __, sorter, extra) => onSortingChange(sorter) : undefined}
        scroll={{ y: "calc(100vh - 250px)" }}
        rowKey="id"
        rowSelection={
          rowsSelectable
            ? {
                selectedRowKeys: selectedRowKeys.current,
                onChange: onRowSelectChange,
              }
            : undefined
        }
        footer={
          showFooter
            ? showClearSelected
              ? rowSelectFooter(
                  selectedRowKeys.current,
                  clearAllSelectedRows,
                  t,
                  footerContent
                )
              : () => footerContent
            : undefined
        }
      />
    </>
  );
}

const getVisibleColumnDefinitions = (
  t: TFunction,
  handleCellClick: (urlPathPart: string, id: string) => void,
  visibleColumns: BeaconTableColumns[],
  disableCellClick: boolean,
  showSorter?: boolean
) => {
  const columns = [
    {
      key: "Serial",
      title: t("beacon:serial"),
      render: (b: Beacon) => b.serial || b.id,
      sorter: showSorter,
      onCell: (b: Beacon) =>
        !disableCellClick
          ? {
              onClick: () => {
                handleCellClick("/beacons/", b.id);
              },
              style: { cursor: "pointer" },
            }
          : {},
    },
    {
      key: "OnOffSwitch",
      title: t("beacon:status"),
      render: (b: Beacon) => <BeaconStatus beacon={b} />,
      sorter: showSorter,
    },
    {
      key: "Iccid",
      title: t("beacon:iccid"),
      render: (b: Beacon) => b.iccid,
      sorter: showSorter,
    },
    // hwv & fwv sorting not supported by current API (Nov, 2024)
    {
      key: "Hwv",
      title: t("beacon:hardware-version"),
      render: (b: Beacon) => b.hwv,
      sorter: false,
    },
    {
      key: "Fwv",
      title: t("beacon:firmware-version"),
      // width: 100,
      render: (b: Beacon) => b.fwv,
      sorter: false,
    },
    // imei not yet implemented in current API (Nov, 2024)
    {
      key: "Imei",
      title: "IMEI",
      render: (b: Beacon) => b.imei ?? "",
      sorter: false,
    },

    // manufacturedOn sorting not supported by current API (Nov, 2024)
    // manufacturedOn stored as string in current API (Nov, 2024)
    {
      key: "ManufacturedOn",
      title: t("beacon:manufactured-on"),
      render: (b: Beacon) =>
        b.manufacturedOn
          ? new Date(b.manufacturedOn.toString()).toLocaleString()
          : "",
      sorter: false,
    },
    {
      key: "ConstructionSiteName",
      title: t("translations:construction-site"),
      render: (b: Beacon) => b.constructionSiteName,
      sorter: showSorter,
      onCell: (b: Beacon) =>
        !disableCellClick
          ? {
              onClick: () => {
                handleCellClick(
                  "/construction-projects/",
                  b.constructionSiteId ?? ""
                );
              },
              style: { cursor: "pointer" },
            }
          : {},
    },
    {
      key: "ConstructionProjectName",
      title: t("translations:construction-project"),
      render: (b: Beacon) => b.constructionProjectName,
      sorter: showSorter,
      onCell: (b: Beacon) =>
        !disableCellClick
          ? {
              onClick: () => {
                handleCellClick(
                  "/construction-projects/",
                  b.constructionProjectId ?? ""
                );
              },
              style: { cursor: "pointer" },
            }
          : {},
    },
    {
      key: "OrganizationName",
      title: t("translations:organization"),
      render: (b: Beacon) => b.organizationName,
      sorter: showSorter,
      onCell: (b: Beacon) =>
        !disableCellClick
          ? {
              onClick: () => {
                handleCellClick("/organizations/", b.organizationId ?? "");
              },
              style: { cursor: "pointer" },
            }
          : {},
    },
    {
      key: "LastMessageReceivedAt",
      title: t("beacon:last-message-received-at"),
      render: (b: Beacon) => b.lastMessageReceivedAt?.toLocaleString(),
      sorter: showSorter,
    },
    {
      key: "ConfigurationButton",
      width: 100,
      render: (b: Beacon) => <BeaconConfigurationModal beacon={b} />,
    },
  ];

  return columns.filter((column) =>
    visibleColumns.includes(column.key as BeaconTableColumns)
  );
};

const rowSelectFooter = (
  selectedRowIds: string[] | undefined,
  onClearAllSelectedBeacons: () => void,
  t: TFunction,
  footerContent?: React.ReactNode
) => {
  return () => (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "auto auto auto auto auto auto auto auto 1fr",
        gridColumnGap: "1em",
      }}
    >
      <Badge count={selectedRowIds?.length} style={{ backgroundColor: "gray" }}>
        <Button
          disabled={selectedRowIds?.length === 0}
          onClick={() => onClearAllSelectedBeacons()}
        >
          {t("construction-site:clear-selected-beacons")}
        </Button>
      </Badge>
      {footerContent}
    </div>
  );
};

const beaconFilter = (
  onFilterChanged: (e: React.ChangeEvent<HTMLInputElement>) => void,
  filter: string,
  t: TFunction
) => {
  return (
    <Input
      aria-label="beacon-table-filter"
      suffix={<SearchOutlined />}
      placeholder={t("beacon:filter-placeholder")}
      onChange={onFilterChanged}
      allowClear
      style={{ margin: "1rem", width: "25em" }}
    />
  );
};

const beaconAlerts = (filter: string) => {
  return (
    <>
      <BeaconsErrors severity={ErrorSeverity.Error} filter={filter} />
      <BeaconsErrors severity={ErrorSeverity.Warning} filter={filter} />
    </>
  );
};

const mapSortingToApiDefintions = (sorting: SorterResult<Beacon>) => {
  const sortColumnId =
    BeaconSortingColumn[sorting.columnKey as keyof typeof BeaconSortingColumn];

  switch (sorting.order) {
    case "ascend":
      return {
        sortColumnId,
        sortOrderId: BeaconSortingOrder.Ascending,
      };
    case "descend":
      return {
        sortColumnId,
        sortOrderId: BeaconSortingOrder.Descending,
      };
    default:
      return {
        sortColumnId: undefined,
        sortOrderId: undefined,
      };
  }
};
