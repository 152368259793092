import { DataNode } from "antd/lib/tree";
import { Beacon } from "../../../api";

export interface TreeNode extends DataNode {
  title: TreeTitle;
  id: string;
  key: string;
  icon?: React.ReactNode;
  children?: TreeNode[];
}

export interface TreeTitle {
  title: string;
  data: Beacon;
}

export const filteredNodes = (
  node: TreeNode[],
  search: string | null
): [TreeNode[], string[]] => {
  if (!node || !Array.isArray(node)) return [[], []];

  let filteredNodesKey: string[] = [];

  node.forEach((node) => {
    // 1. Current branch title is target or target is null
    // Null target not provided => add all ids to ret
    if (
      !search ||
      node.title?.title.toString().toLowerCase().includes(search.toLowerCase())
    ) {
      // a. Has children
      if (node.children && Array.isArray(node.children)) {
        filteredNodesKey = [
          ...filteredNodesKey,
          node.key,
          ...filteredNodes(node.children, null)[1],
        ];

        // b. Doesn't have children
      } else {
        filteredNodesKey = [...filteredNodesKey, node.key];
      }

      // 2. Current branch is not target
    } else {
      // a. Has children
      if (node.children && Array.isArray(node.children)) {
        // Check if traversal returns anything
        let children = filteredNodes(node.children, search);
        if (children[0].length != 0) {
          filteredNodesKey = [...filteredNodesKey, node.key, ...children[1]];
        }
      }
      // b. Doesn't have children -> don't add anything to ret
    }
  });

  const filteredNodeData = filterTreeDataByKeys(node, filteredNodesKey);

  return [filteredNodeData, filteredNodesKey];
};

export const transformToTreeData = (
  data: Beacon[] | undefined,
  isSolutionOperator: boolean
) => {
  if (data === undefined) return;

  const buildTree = (
    items: Beacon[],
    groupByKeys: (keyof Beacon)[]
  ): TreeNode[] => {
    if (!groupByKeys.length) return [];

    const [currentKey, ...remainingKeys] = groupByKeys;
    const group: { [key: string]: Beacon[] } = {};
    const nullKey = Math.random().toString(16).slice(2);

    items.forEach((item: Beacon) => {
      const key = String(item[currentKey as keyof Beacon] ?? nullKey);

      if (!group[key]) {
        group[key] = [];
      }
      group[key].push(item);
    });

    return Object.entries(group).map(([key, groupedItems]) => {
      const firstItem = groupedItems[0];

      const currentKeyName = (): keyof Beacon => {
        if (currentKey === "serial") return currentKey;
        else return currentKey.replace("Id", "Name") as keyof Beacon;
      };

      const title = (key: string) => {
        return {
          title:
            key === nullKey
              ? "Unassigned"
              : String(firstItem[currentKeyName()]),
          data: firstItem,
        };
      };

      return {
        title: title(key),
        key,
        id: currentKeyName(),
        icon: <></>,
        children: buildTree(groupedItems, remainingKeys),
      };
    });
  };

  // Define the grouping hierarchy
  const groupingKeys: (keyof Beacon)[] = [
    "organizationId",
    "constructionProjectId",
    "constructionSiteId",
    "serial",
  ];
  const transformedData = buildTree(data, groupingKeys);

  // If the user is a Solution Operator, return the top-level node (all data including organization)
  // Otherwise, return the children of the top-level node (all data excluding organization)
  return isSolutionOperator
    ? transformedData
    : transformedData.flatMap((node: TreeNode) => node.children || []);
};

export const filterTreeDataByKeys = (
  data: TreeNode[],
  keys: string[]
): TreeNode[] => {
  const keySet = new Set(keys);

  const filterNodes = (nodes: TreeNode[]): TreeNode[] => {
    return nodes
      .map((node) => {
        if (keySet.has(node.key)) {
          const filteredChildren = node.children
            ? filterNodes(node.children)
            : [];
          return {
            ...node,
            children: filteredChildren.length ? filteredChildren : undefined,
          };
        }
        return null;
      })
      .filter((node) => node !== null) as TreeNode[];
  };

  const filteredNodeData = filterNodes(data);
  return filteredNodeData;
};

export const getAllKeys = (treeData: TreeNode[] | undefined): string[] => {
  if (!treeData) {
    return [];
  }
  const keys: string[] = [];

  const traverse = (nodes: TreeNode[]) => {
    nodes.forEach((node) => {
      if (node.key) {
        keys.push(node.key);
      }
      if (node.children) {
        traverse(node.children);
      }
    });
  };

  traverse(treeData);

  return keys;
};

export const getChildKeys = (node: TreeNode | undefined): string[] => {
  if (!node) return [];

  const keys: string[] = [node.key];

  const traverse = (nodes: TreeNode[]) => {
    nodes.forEach((childNode) => {
      if (childNode.key) {
        keys.push(childNode.key);
      }
      if (childNode.children) {
        traverse(childNode.children);
      }
    });
  };

  if (node.children) {
    traverse(node.children);
  }

  return keys;
};

export const getParentKeys = (key: string, tree: TreeNode[]): string[] => {
  const parentKeys: string[] = [];

  const traverse = (nodes: TreeNode[], parent: string | undefined) => {
    nodes.forEach((node) => {
      if (node.key === key) {
        if (parent) {
          parentKeys.push(parent);
          parentKeys.push(...getParentKeys(parent, tree));
        }
      }
      if (node.children) {
        traverse(node.children, node.key);
      }
    });
  };

  traverse(tree, undefined);

  return parentKeys;
};

export const findNode = (
  nodes: TreeNode[],
  key: string
): TreeNode | undefined => {
  for (const node of nodes) {
    if (node.key === key) {
      return node;
    }
    if (node.children) {
      const foundNode = findNode(node.children, key);
      if (foundNode) {
        return foundNode;
      }
    }
  }
  return undefined;
};
