import { MinusCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  useAssignConstructionSite,
  useUnassignBeaconsFromOrganization,
} from "../../api";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { Badge } from "../Badge";

interface UnassignBeaconsButtonSiteProps {
  constructionProjectId: string;
  selectedBeaconIds: string[];
}

export function UnassignBeaconsButtonSite({
  selectedBeaconIds,
  constructionProjectId,
}: UnassignBeaconsButtonSiteProps) {
  const [isUnassigning, setIsUnassigning] = useState(false);
  const unassignBeaconsFromSite = useAssignConstructionSite(
    constructionProjectId
  );
  const { t } = useTranslation();
  const dataRefreshContext = useContext(DataRefreshContext);

  const onUnassignBeaconsFromSite = async () => {
    try {
      setIsUnassigning(true);
      await unassignBeaconsFromSite({
        beaconIds: selectedBeaconIds,
      });
      dataRefreshContext.refresh();
    } finally {
      setIsUnassigning(false);
    }
  };

  return (
    <>
      <Badge count={selectedBeaconIds.length}>
        <Button
          danger
          disabled={selectedBeaconIds.length === 0}
          loading={isUnassigning}
          onClick={onUnassignBeaconsFromSite}
          title={t("construction-site:unassign-beacons", {
            count: selectedBeaconIds.length,
          })}
        >
          <MinusCircleOutlined />
          {t("construction-site:unassign-beacons", {
            count: selectedBeaconIds.length,
          })}
        </Button>
      </Badge>
    </>
  );
}

interface UnassignBeaconsButtonOrganizationProps {
  organizationId: string;
  selectedBeaconIds: string[];
  onClearSelectedBeaconIds(): void;
}

export function UnassignBeaconsButtonOrganization({
  organizationId,
  selectedBeaconIds,
  onClearSelectedBeaconIds,
}: UnassignBeaconsButtonOrganizationProps) {
  const [isUnassigning, setIsUnassigning] = useState(false);
  const unassignBeaconsFromOrganization = useUnassignBeaconsFromOrganization();
  const { t } = useTranslation();
  const dataRefreshContext = useContext(DataRefreshContext);

  const onUnassignBeaconsFromOrganization = async () => {
    try {
      setIsUnassigning(true);
      await unassignBeaconsFromOrganization({
        beaconIds: selectedBeaconIds,
        organizationId,
      });
      onClearSelectedBeaconIds();
      dataRefreshContext.refresh();
    } finally {
      setIsUnassigning(false);
    }
  };

  return (
    <>
      <Badge count={selectedBeaconIds.length}>
        <Button
          danger
          disabled={selectedBeaconIds.length === 0}
          loading={isUnassigning}
          onClick={onUnassignBeaconsFromOrganization}
          title={t("organization:unassign-beacons-message", {
            count: selectedBeaconIds.length,
          })}
        >
          <MinusCircleOutlined />
          {t("organization:unassign-beacons", {
            count: selectedBeaconIds.length,
          })}
        </Button>
      </Badge>
    </>
  );
}
