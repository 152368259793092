type CSSWidth =
  | `${number}px`
  | `${number}em`
  | `${number}rem`
  | `${number}%`
  | "auto";

interface TruncatedLongTextProps {
  text: string;
  maxLength: CSSWidth;
}

export function TruncatedLongText({ text, maxLength }: TruncatedLongTextProps) {
  return (
    <div
      style={{
        maxWidth: `${maxLength}`,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      title={text}
    >
      {text}
    </div>
  );
}
