import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Beacon } from "../../../api/models";

export interface BeaconAboutCardProps {
  beacon: Beacon;
}

export function BeaconAboutCard({ beacon }: BeaconAboutCardProps) {
  const history = useHistory();
  const { t } = useTranslation();

  function renderBeaconProperty(beacon: Beacon, property: keyof Beacon) {
    if (
      (property === "constructionProjectName" ||
        property === "organizationName" || property === "constructionSiteName") &&
      beacon[property] !== null
    ) {
      return beacon ? <u>{beacon[property] || "-"}</u> : <>Loading...</>;
    }
    return beacon ? <>{beacon[property] || "-"}</> : <>Loading...</>;
  }

  return (
    <div className="card">
      <div className="card-header">{t("beacon:about-this-device")}</div>
      <div style={{ overflow: "auto" }}>
        <div>
          <table className="about-device-card-table">
            <tbody>
              <tr className="about-device-card-row">
                <td className="about-device-card-col-a">
                  {t("beacon:thing-name")}
                </td>
                <td className="about-device-card-col-b">
                  {renderBeaconProperty(beacon, "id")}
                </td>
              </tr>
              <tr className="about-device-card-row">
                <td className="about-device-card-col-a">ICCID</td>
                <td className="about-device-card-col-b">
                  {renderBeaconProperty(beacon, "iccid")}
                </td>
              </tr>
              <tr className="about-device-card-row">
                <td className="about-device-card-col-a">
                  {t("beacon:hardware-version")}
                </td>
                <td className="about-device-card-col-b">
                  {renderBeaconProperty(beacon, "hwv")}
                </td>
              </tr>
              <tr className="about-device-card-row">
                <td className="about-device-card-col-a">
                  {t("beacon:firmware-version")}
                </td>
                <td className="about-device-card-col-b">
                  {renderBeaconProperty(beacon, "fwv")}
                </td>
              </tr>
              <tr className="about-device-card-row">
                <td className="about-device-card-col-a">
                  {t("translations:organization")}
                </td>
                <td
                  className="about-device-card-col-b"
                  onClick={() => {
                    if (beacon.organizationName) {
                      history.push(`/organizations/${beacon.organizationId}`);
                    }
                  }}
                >
                  {renderBeaconProperty(beacon, "organizationName")}
                </td>
              </tr>
              <tr className="about-device-card-row">
                <td className="about-device-card-col-a">
                  {t("translations:construction-project")}
                </td>
                <td
                  className="about-device-card-col-b"
                  onClick={() => {
                    if (beacon.constructionProjectName) {
                      history.push(`/construction-projects`, {
                        selectedId: beacon.constructionProjectId,
                      });
                    }
                  }}
                >
                  {renderBeaconProperty(beacon, "constructionProjectName")}
                </td>
              </tr>
              <tr className="about-device-card-bottom-row">
                <td className="about-device-card-col-a">
                  {t("translations:construction-site")}
                </td>
                <td
                  className="about-device-card-col-b"
                  onClick={() => {
                    if (beacon.constructionProjectName) {
                      history.push(`/construction-projects`, {
                        selectedId: beacon.constructionSiteId,
                      });
                    }
                  }}
                >
                  {renderBeaconProperty(beacon, "constructionSiteName")}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
