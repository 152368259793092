import { CloseOutlined } from "@ant-design/icons";
import { Alert, Button, Drawer, Spin } from "antd";
import { useContext, useMemo } from "react";
import { Beacon, useConstructionProjectSite } from "../../../../api";
import { PagedApiState } from "../../../../api/useApiState";
import { EditableText } from "../../../../util/EditableText";
import "../drawer.scss";
import { useUpdateConstructionSiteInformation } from "../useUpdateInformation";
import { BeaconDetails } from "./BeaconDetails";
import { SummaryInformation } from "./SummaryInformation";
import { useNodeSelectedContext } from "../../NodeSelectedContext";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../../../contexts/authContext";

interface ConstructionSiteDrawerProps {
  constructionProjectId: string;
  constructionProjectName: string;
  beacons: PagedApiState<Beacon>;
  constructionSiteId: string;
  drawerVisible: boolean;
  onSave: (refreshTimeStamp: string) => void;
}

export function ConstructionSiteDrawer({
  constructionProjectId,
  constructionProjectName,
  beacons,
  constructionSiteId,
  drawerVisible,
  onSave,
}: ConstructionSiteDrawerProps) {
  const { t } = useTranslation();
  const { setSelectedNode } = useNodeSelectedContext();

  const authContext = useContext(AuthContext);
  const currentUserIsRSM = authContext.currentUser?.accessToken?.hasRole(
    "road-safety-manager"
  );

  const showProjectDrawer = (projectId: string) => {
    setSelectedNode({
      selectedProjectId: projectId,
      projectDrawerVisible: true,
      siteDrawerVisible: false,
    });
  };

  const constructionProjectSiteData = useConstructionProjectSite(
    constructionProjectId,
    constructionSiteId
  );

  const { updateResult, updateSiteName } = useUpdateConstructionSiteInformation(
    constructionProjectId,
    constructionSiteId,
    onSave
  );

  const filteredSiteBeacons: PagedApiState<Beacon> = useMemo(() => {
    if (!beacons.value) {
      return {
        ...beacons,
        value: { totalCount: 0, items: [] },
      };
    }
    const filteredBeacons = beacons.value.items.filter(
      (beacon) => beacon.constructionSiteId === constructionSiteId
    );
    return {
      ...beacons,
      value: {
        totalCount: filteredBeacons.length,
        items: filteredBeacons,
      },
    };
  }, [beacons, constructionSiteId]);

  const filteredProjectBeacons: PagedApiState<Beacon> = useMemo(() => {
    if (!beacons.value) {
      return {
        ...beacons,
        value: { totalCount: 0, items: [] },
      };
    }
    const filteredBeacons = beacons.value.items.filter(
      (beacon) => beacon.constructionProjectId === constructionProjectId
    );

    // Filter out beacons that are already assigned to a site and part of the project
    // To be used in assign beacon modal
    const filteredBeaconsNoSite = filteredBeacons.filter(
      (beacon) => beacon.constructionSiteId === null
    );

    return {
      ...beacons,
      value: {
        totalCount: filteredBeaconsNoSite.length,
        items: filteredBeaconsNoSite,
      },
    };
  }, [beacons, constructionSiteId]);

  return (
    <Drawer
      title={
        <div>
          {constructionProjectSiteData.loading ? (
            <div style={{ display: "flex", justifyContent: "center" }}>
              {true ? <Spin size="default" /> : undefined}
            </div>
          ) : (
            <div className="title-container">
              {currentUserIsRSM ? (
                <EditableText
                  text={constructionProjectSiteData.value?.name}
                  style={{ width: "450px" }}
                  saveCallback={(value) => updateSiteName(value)}
                />
              ) : (
                constructionProjectSiteData.value?.name
              )}

              <Button
                type="text"
                className="close-card-drawer-button"
                icon={<CloseOutlined />}
                onClick={() => setSelectedNode({ siteDrawerVisible: false })}
              />
            </div>
          )}
        </div>
      }
      placement="right"
      closable={false}
      onClose={() => setSelectedNode({ siteDrawerVisible: false })}
      visible={drawerVisible}
      width={600}
    >
      {updateResult.status === 401 &&
        updateResult.updatedValue.key === "siteName" && (
          <Alert
            message={t("construction-site:update-site-name-failed")}
            type="error"
            closable
          />
        )}
      {updateResult.status === 200 &&
        updateResult.updatedValue.key === "siteName" && (
          <Alert
            message={t("construction-site:update-site-name-success")}
            type="success"
            closable
          />
        )}
      {constructionProjectSiteData.loading ? (
        <Spin size="default" className="centered" />
      ) : (
        <>
          <SummaryInformation
            dateCreated={
              new Date(constructionProjectSiteData.value?.createdAt ?? "") || ""
            }
            project={constructionProjectName}
            projectId={constructionProjectId}
            location={"placeholder"}
            isLoading={constructionProjectSiteData.loading}
            handleProjectClicked={(projectId) => {
              showProjectDrawer(projectId);
            }}
          />
          <BeaconDetails
            constructionProjectId={constructionProjectId}
            constructionSiteId={constructionSiteId}
            siteBeacons={filteredSiteBeacons}
            projectBeacons={filteredProjectBeacons}
          />
        </>
      )}
    </Drawer>
  );
}
