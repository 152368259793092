import { createContext, ReactNode, useContext, useState } from "react";

interface NodeSelectedContextType {
  selectedNode: NodeContextData;
  nodeVisitiblyDisabledList: string[];
  setSelectedNode: (node: NodeContextData) => void;
  setNodeVisitiblyDisabledList: (node: string[]) => void;
}

export interface NodeContextData {
  selectedSiteId?: string;
  selectedSiteName?: string;
  selectedProjectId?: string;
  selectedProjectName?: string;
  selectedId?: string;
  projectDrawerVisible?: boolean;
  siteDrawerVisible?: boolean;
}

export const defaultSelectedNode: NodeContextData = {
  selectedSiteId: "",
  selectedSiteName: "",
  selectedProjectId: "",
  selectedProjectName: "",
  selectedId: "",
  projectDrawerVisible: false,
  siteDrawerVisible: false,
};

const defaultContextValue: NodeSelectedContextType = {
  selectedNode: defaultSelectedNode,
  nodeVisitiblyDisabledList: [],
  setSelectedNode: () => {},
  setNodeVisitiblyDisabledList: () => {},
};

const NodeSelectedContext =
  createContext<NodeSelectedContextType>(defaultContextValue);

export const useNodeSelectedContext = () => useContext(NodeSelectedContext);

export const NodeSelectedProvider = ({ children }: { children: ReactNode }) => {
  const [selectedNode, setSelectedNode] =
    useState<NodeContextData>(defaultSelectedNode);

  const [nodeVisitiblyDisabledList, setNodeVisitiblyDisabledList] = useState<
    string[]
  >([]);

  return (
    <NodeSelectedContext.Provider
      value={{
        selectedNode,
        nodeVisitiblyDisabledList,
        setSelectedNode,
        setNodeVisitiblyDisabledList,
      }}
    >
      {children}
    </NodeSelectedContext.Provider>
  );
};
