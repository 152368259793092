import { Icon, LatLngBounds } from "leaflet";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useRef } from "react";
import {
  MapContainer,
  Marker,
  Polyline,
  Polygon,
  Popup,
  TileLayer,
  useMapEvent,
} from "react-leaflet";
import { LinearRing, MarkerColor } from "../api";
import { Spin } from "antd";

const createMarkerIcon = (color: string) =>
  new Icon({
    iconUrl: `${process.env.PUBLIC_URL}/images/marker-icon-2x-${color}.png`,
    shadowUrl: `${process.env.PUBLIC_URL}/images/marker-shadow.png`,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
  });

const icons: Record<string, Icon> = {
  blue: createMarkerIcon("blue"),
  green: createMarkerIcon("green"),
  grey: createMarkerIcon("grey"),
  red: createMarkerIcon("red"),
  orange: createMarkerIcon("orange"),
};

export interface Position {
  lat: number;
  lon: number;
}

export interface MarkerData extends Position {
  title: string;
  id: string;
  color?: MarkerColor;
  hasError?: boolean;
  errorText?: React.ReactNode;
  errorSeverity?: "error" | "warning";
  onClick?: () => void;
}

export interface PolyLineData {
  line: Position[];
  id: string;
  title?: string;
  color?: React.CSSProperties["color"];
  width?: number;
  onClick?: () => void;
}

export interface PolygonData {
  title?: string;
  ring: LinearRing;
  color?: React.CSSProperties["color"];
  id?: string;
  onClick?: () => void;
}

interface MapProps {
  markers: MarkerData[];
  polylines?: PolyLineData[];
  polygons?: PolygonData[];
  isLoading?: boolean;
  suppressBoundsUpdate?: boolean;
  onMapClicked?: (e: L.LeafletMouseEvent) => void;
}

interface ClickOutsideHandlerProps {
  onMapClick: (e: L.LeafletMouseEvent) => void;
}

// This component is used to handle clicks on map outside of markers
const ClickOutsideHandler = ({ onMapClick }: ClickOutsideHandlerProps) => {
  useMapEvent("click", (e) => {
    if (
      !(e.originalEvent.target as HTMLElement).closest(".leaflet-interactive")
    ) {
      onMapClick(e);
    }
  });

  return null;
};

export function Map2({
  markers,
  polylines,
  polygons,
  isLoading,
  suppressBoundsUpdate,
  onMapClicked,
}: MapProps) {
  const mapRef = useRef<L.Map | null>(null);

  const prevMarkersRef = useRef(markers);

  useEffect(() => {
    // Exit early if markers haven't changed
    if (
      JSON.stringify(prevMarkersRef.current) === JSON.stringify(markers) ||
      suppressBoundsUpdate
    ) {
      return;
    }

    prevMarkersRef.current = markers;

    if (mapRef.current) {
      const map = mapRef.current;
      const bounds = new LatLngBounds([]);

      markers.forEach((marker) => {
        bounds.extend([marker.lat, marker.lon]);
      });

      polygons?.forEach((polygon) => {
        polygon.ring.forEach((point) => {
          bounds.extend([point.latitude, point.longitude]);
        });
      });

      if (bounds.isValid()) {
        map.fitBounds(bounds);
      }
    }
  }, [markers]);

  return (
    <div style={{ position: "relative", height: "100%", width: "100%" }}>
      <MapContainer
        center={[51.378, 10.052]}
        zoom={6}
        style={{ height: "100%", width: "100%" }}
        whenCreated={(mapInstance) => {
          mapRef.current = mapInstance;
        }}
      >
        <TileLayer
          attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {onMapClicked && <ClickOutsideHandler onMapClick={onMapClicked} />}
        {markers.map((marker) => (
          <Marker
            title={marker.title}
            key={marker.title}
            position={[marker.lat, marker.lon]}
            icon={icons[marker.color || "blue"]}
            eventHandlers={{ click: marker.onClick }}
          >
            <Popup>{marker.title}</Popup>
          </Marker>
        ))}
        {polygons &&
          polygons.map((polygon, index) => (
            <Polygon
              key={index}
              positions={polygon.ring.map((p) => [p.latitude, p.longitude])}
              pathOptions={{ color: polygon.color }}
              eventHandlers={{ click: polygon.onClick }}
            />
          ))}
        {polylines &&
          polylines.map((polyline, index) => (
            <Polyline
              key={index}
              color={polyline.color || "#1890ff"}
              weight={polyline.width || 3}
              positions={polyline.line.map((p) => [p.lat, p.lon])}
              eventHandlers={{ click: polyline.onClick }}
            ></Polyline>
          ))}
      </MapContainer>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000,
          }}
        >
          <Spin size="large" />
        </div>
      )}
    </div>
  );
}
